@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

html {
    background-color: #1D2022;
    padding: auto;
}

section {
    margin: 0px 30px 0px 10px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

div {
    margin-top: 20px;
}

.contacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    column-gap: 30px;
}

.contacts a {
    text-decoration: none;
    color: #FEFCFF;
    font-family: 'Noto Sans', sans-serif;
    margin: 0px;
    padding: 0px;
}

.contacts a:hover {
    color: #BFF3D9;
}

h1 {
    color: #FEFCFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 40px;
    font-weight: bolder;
}

p {
    color: #FEFCFF;
    font-family: 'Noto Sans', sans-serif;
    margin: 0px;
    padding: 0px;
}